.blogListCount {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #343a40;
  display: block;
  margin-right: 40px;
}

.blogList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.createBlogPage {
  .publishSection {
    max-width: 306px;
    background: #f9f9f9;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    margin-top: 22px;

    .titleSection {
      padding: 11px 20px;
      border-bottom: 1px solid #dbdbdb;
      .publishTitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #343a40;
      }
    }

    .contentSection {
      padding: 11px 20px;
      border-bottom: 1px solid #dbdbdb;

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin-right: 12px;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        margin-right: 15px;
      }
    }

    .actionsSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 20px;
    }
  }
}
.previewModalContainer {
  min-width: 1024px;
  min-height: 600px;
}
