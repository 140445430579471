.sideNavContainer {
  display: block;
  vertical-align: top;
  height: 100%;
  width: 18%;
  background: #3a4258;

  .appLogo {
    display: flex;
    align-items: center;
    height: 78px;
    padding-left: 20px;
    background: #f9f9f9;
  }

  .menuItem {
    padding: 16px 20px;
  }

  .menuItemText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #ffffff;
    opacity: 0.5;
  }

  .activeMenuItem {
    li {
      border-left: 3px solid #c4214a;
      background: rgba(255, 255, 255, 0.08);
    }
    span {
      opacity: 1;
    }
  }
}
.p0 {
  padding: 0px;
}
