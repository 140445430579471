.appLayoutContainer {
  display: block;
  vertical-align: top;
  width: 82%;
  overflow: auto;
}

.appLayoutContent {
  padding: 30px 100px 30px 40px;
}
