@import 'src/styles/utilities/mixins';

.cardWrapper {
  position: relative;
  border-radius: 16px;
  flex: 0 0 calc(33% - 15px);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;

  @include tablet-min() {
    flex-basis: calc(50% - 15px);
  }

  @include mobile-max() {
    flex-basis: 100%;
  }

  .status {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: #171717;
    border-radius: 100px;
    color: #ffffff;
    padding: 3px 10px;
  }

  .img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
    border-radius: 16px;
  }

  .cardInner {
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 20px;

    .description {
      .title {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #333333;
        margin-bottom: 16px;
      }

      .text {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #666;
      }
    }
    .buttons {
      height: 100%;
      display: flex;
      align-items: flex-end;

      .more {
        display: flex;
        font-weight: 700;
        line-height: 24px;
        color: #c4214a;
        padding-bottom: 3px;
        font-size: 16px;
        img {
          margin-left: 4px;
        }
      }
      .edit {
        img {
          margin-right: 4px;
        }
        margin-left: auto;
        font-weight: 600;
        line-height: 19px;
        font-size: 16px;
      }
      .delete {
        img {
          margin-right: 4px;
        }
        font-weight: 600;
        line-height: 19px;
        font-size: 16px;
      }
    }
  }
}
