.inputLabel {
  color: #171717;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
}

.outlinedInput {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  background: #f9f9f9;
  border: 1px solid #dbdbdb;
  border-radius: 8px;

  input {
    padding: 14px 20px;
  }
}
.borderNone {
  border: none;
}
