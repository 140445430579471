.visibilityModalContainer {
  min-width: 325px;
  border-radius: 16px;

  .radioGroupLabel {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: #171717;
  }

  .radioButtonLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #171717;
  }

  .visiblityActionButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
}
