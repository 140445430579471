.imagePreviewContainer {
  max-width: 800px;
  width: 100%;
}

.previewActionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
