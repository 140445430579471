.headerContainer {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 100px;
  z-index: 1;

  .pageTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #171717;
    margin-right: 40px;
  }

  .searchInput {
    height: 36px;
    border-radius: 20px;
    background: #f9f9f9;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #838383;
  }
  .userName {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
  }
  .borderNone {
    border: none;
  }
}
