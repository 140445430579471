.defaultButton {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border-radius: 8px;
  padding: 12px 20px;
  text-transform: none;
}
.containedButton {
  color: #ffffff;
}
.startIcon {
  margin-right: 5px;
}
