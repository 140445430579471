.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 500px;
  width: 100%;

  .title {
    text-align: center;
    color: rgba(23, 23, 23, 1);
  }

  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    .input {
      margin-bottom: 20px;
    }
  }

  .button {
    button {
      color: #fff;
      background-color: rgba(255, 164, 37, 1);
      padding: 12px 60px;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;

      svg {
        animation: 1s linear 0s normal none infinite running rot;
        -webkit-animation: 1s linear 0s normal none infinite running rot;
        width: 100px;
      }
    }
    button:disabled {
      background-color: rgba(255, 164, 37, 0.4);
    }
  }
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
