.defaultButton {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  border-radius: 8px;
  padding: 12px 20px;
  text-transform: none;
}
.containedButton {
  color: #ffffff;
}
.outlinedButton {
  border: 1px solid #838383;
  color: #838383;
  &:hover {
    border: 1px solid #838383;
    background-color: transparent;
  }
}
.startIcon {
  margin-right: 5px;
}
.endIcon {
  margin-left: 3px;
}
