.select::before {
  border-bottom: none !important;
}
.select::after {
  border-bottom: none !important;
}

.wrapper {
  border-top: 1px solid #dbdbdb;
  padding: 12px 0;
}
