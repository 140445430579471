.element {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.aboutText {
  font-size: 14px;
  margin-right: 20px;
}

.blockText {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  line-height: 24px;
}

.arrayText {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  line-height: 24px;
  font-weight: 500;
  padding: 8px 40px;
  border: 2px solid var(--color-primary-yellow);
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #c4214a;
  margin-bottom: 24px;
}

.line {
  height: 1px;
  background-color: #dbdbdb;
  margin: 26px 0;
}
