.publishDateModalContainer {
  min-width: 390px;
  border-radius: 16px;

  .modalTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 150%;
    color: #171717;
  }

  .timeSection {
    display: flex;
    align-items: center;
    position: relative;
    top: -40px;

    .timeLabel {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #171717;
      margin-right: 10px;
    }
  }

  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
  }
}
