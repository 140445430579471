@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('../assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}
