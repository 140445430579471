.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
.wrapper,
.wrapper:active,
.wrapper:hover {
  color: var(--color-primary-black);
}
